import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import HeroPages from '../components/HeroPages';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import { Home } from '../app/home';
import { Insurance } from '../app/insurance';
import { Financial } from '../app/financial';
import axios from 'axios';
import Progress from "../components/Progress";

const stepPages = [Home, Insurance, Financial];

function Models() {
  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState({});
  const [steps, setSteps] = useState([
    {
      label: 'Home',
      isValid: undefined
    },
    {
      label: 'Insurance',
      isValid: undefined
    },
    {
      label: 'Financial',
      isValid: undefined
    }
  ]);
  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid = steps.slice(0, step).findIndex(currentStep => currentStep.isValid === false) === -1;

  
  const generateUniqueId = () => {
    // Generate a random number between 1000 and 9999
    return Math.floor(Math.random() * (9999 - 1000 + 1)) + 1000;
  };

  const onStepSubmit = event => {
    const { isValid, values } = event;
    const currentSteps = steps.map((currentStep, index) => ({
      ...currentStep,
      isValid: index === step ? isValid : currentStep.isValid
    }));
    setSteps(currentSteps);
    setStep(prevStep => Math.min(prevStep + 1, lastStepIndex));

    // Generate the 'id' field as a unique integer
    const id = generateUniqueId();
    values.id = id;

    if (isLastStep && isPreviousStepsValid && isValid) {
      // Send the data to the backend API
      axios
        .post('${process.env.REACT_APP_API_DOMAIN}/rr2-scenario/', values)
        .then(response => {
          // Handle the response if needed
          console.log(response.data);
        })
        .catch(error => {
          // Handle the error if needed
          console.error(error.response?.data);
        });
    }
  };

  const onPrevClick = event => {
    event.preventDefault();
    setStep(prevStep => Math.max(prevStep - 1, 0));
  };

  

  return (
    <>
     <Progress/>
      <section className="models-section">
    
        <HeroPages name="Decision Making Tool"  style={{marginBottom:'70px'}} />

{/* To add space between the Hero page and merge step bar */}
        <div style={{ margin: '20px 0' }}> </div>

        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom:'50px' }}>
          <Stepper value={step} items={steps} />

          {/* To add the gap between the step bar and form container */}
          <div style={{ margin: '15px 0' }}> </div>

          <div style={{ alignSelf: 'center', width:'70%' }}>
            <Form
              initialValues={formState}
              onSubmitClick={onStepSubmit}
              render={formRenderProps => (
                <FormElement style={{ width: '100%' }}>
                  {React.createElement(stepPages[step])}
                  <span style={{ marginTop: '40px' ,width:'100%'}} className={'k-form-separator'} />
                  <div
                    style={{ justifyContent: 'space-between', alignContent: 'center' }}
                    className={'k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end'}
                  >
                    <span style={{ alignSelf: 'center', margin:'30px', fontSize:'20px'}}>Step {step + 1} of 3</span>
                    <div>
                      {step !== 0 && (
                        <Button style={{ marginRight: '50px', fontSize:'20px' }} onClick={onPrevClick}>
                          Previous
                        </Button>
                      )}
                      <Button style={{ marginRight: '50px', fontSize:'20px' }}
                        themeColor={'primary'}
                        disabled={isLastStep ? !isPreviousStepsValid : false}
                        onClick={formRenderProps.onSubmit}
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
}

export default Models;


