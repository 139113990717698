import React from "react";

const NotFoundPage = () => {
  const containerStyle = {
    textAlign: "center",
    marginTop: "50px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  };

  const titleStyle = {
    fontSize: "3rem",
    marginBottom: "20px",
    fontWeight: "bold",
    color: "#ff6347", // Coral color
  };

  const messageStyle = {
    fontSize: "1.4rem",
    color: "#666",
    marginTop: "20px",
  };

  return (
    <div style={containerStyle}>
      <h1 style={titleStyle}>404 - Page Not Found</h1>
      <p style={messageStyle}>Oops! The page you are looking for does not exist.</p>
    </div>
  );
};

export default NotFoundPage;
