
import { Link } from "react-router-dom";
import Logo from "../images/logo/logoWhite.png";
import { useState } from "react";
function Navbar() {
  const [nav, setNav] = useState(false);
  const openNav = () => {
    setNav(!nav);
  };
  return (
    <>
      <nav>
        {/* mobile */}
        <div className={`mobile-navbar ${nav ? "open-nav" : ""}`}>
          <div onClick={openNav} className="mobile-navbar__close">
            <i className="fa-solid fa-xmark"></i>
          </div>
          <ul className="mobile-navbar__links">
            <li>
              <Link onClick={openNav} to="/">
                HOME
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/calculation"> 
                DECISION MAKING TOOL
              </Link>
              </li>
            <li>
              <Link onClick={openNav} to="/about" >
                ABOUT
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/team" >
                TEAM
              </Link>
            </li>
            <li>
              <Link onClick={openNav} to="/contact" >
                HELP
              </Link>
            </li>
          </ul>
        </div>
        {/* desktop */}
        <div className="navbar">
          <div className="navbar__img">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img src={Logo} alt="logo-img" />
            </Link>
          </div>
          <ul className="navbar__links">
            <li>
              <Link className="home-link" to="/" >
                HOME
              </Link>
            </li>
            <li>
            
            {/* <a href = {process.env.PUBLIC_URL + '/api.html'} >DECISION MAKING TOOL</a> */}
              {" "}
              <Link className="models-link" to="/calculation">
               DECISION MAKING TOOL  
              </Link>
            </li>
            <li>
              {" "}
              <Link className="about-link" to="/about" >
                ABOUT
              </Link>
            </li>
            <li>
              {" "}
              <Link className="team-link" to="/team" >
                TEAM
              </Link>
            </li>
            <li>
              {" "}
              <Link className="contact-link" to="/contact" >
                HELP
              </Link>
            </li>
          </ul>
  
          {/* mobile */}
          <div className="mobile-hamb" onClick={openNav}>
            <i className="fa-solid fa-bars"></i>
          </div>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
