import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Faq from "../components/Faq";
import Progress from "../components/Progress";
import { useState } from 'react';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    details: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // const response = await fetch('http://127.0.0.1:8000/rr2-helpCeneter/', 
      //  const response = await fetch('https://floodsafehomeapi-dev.azurewebsites.net/rr2-helpCeneter/',
       const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/rr2-helpCeneter/`,

      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Thank you gor reaching us out Form submitted successfully! we will get back to you soon');
        // Clear the form fields after successful submission
        setFormData({
          name: '',
          email: '',
          subject: '',
          details: ''
        });
      } else {
        alert('Failed to submit Form. Please fill all fields and try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Failed to submit message. Please fill all fields and try again.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      <Progress/>
      <section className="contact-page">
        <HeroPages name="Contact" />
        <div className="container">
          <div className="contact-div">
            <div className="contact-div__text">
              <h2>Help center</h2>
              <div className="contact-div__form">
                <form onSubmit={handleSubmit}>
                  <label>
                    Full Name <b>*</b>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.fullName}
                    placeholder='E.g: "Joe Shmoe"'
                    onChange={handleChange}
                  />

                  <label>
                    Email <b>*</b>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    placeholder="youremail@example.com"
                    onChange={handleChange}
                  />

                  <label>
                    Subject <b>*</b>
                  </label>
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    placeholder='E.g: "Results Related"'
                    onChange={handleChange}
                  />

                  <label>
                    How can we help?<b>*</b>
                  </label>
                  <textarea
                    name="details"
                    value={formData.message}
                    placeholder="Please write here.."
                    onChange={handleChange}
                  ></textarea>

                  <button type="submit">
                    <i className="fa-solid fa-envelope-open-text"></i>&nbsp; Submit
                  </button>
                </form>
              </div>
            </div>
            <div className="contact-div__text">
              <p>
                <b>Need more help?</b>
              </p>
              <a>
                <i className="fa-solid fa-phone"></i>&nbsp; (225) 578-7913
              </a>
              <a >
                <i className="fa-solid fa-envelope"></i>&nbsp;
                LaHouse@agcenter.lsu.edu
              </a>
              <a >
                <i className="fa-solid fa-location-dot"></i>&nbsp; 2858 Gourrier Ave, Baton Rouge, LA 70820
              </a>
            </div>
          </div>
        </div>
        <Faq />
        <Footer />
      </section>
    </>
  );
}

export default Contact;
