import freeboard from "../images/plan/freeboard.png";

function Freeboard() {
  return (
    <>
      <section className="plan-section">
        <div className="container">
          <div className="plan-container">
            <div className="plan-container__title">
              <h2>Freeboard</h2>
            </div>
            <div className="plan-container__boxes">
              <div className="plan-container__boxes__box">
                <img src={freeboard} alt="icon_img" />
              </div>

              <div className="plan-container__boxes__box">
                <p>
                 Freeboard is the elevation of a building's lowest floor above the minimum base flood elevation. Let's identify, evaluate, and address the economic, physical, personal perception, and policy incentives/barriers to increased freeboard for new home construction in Louisiana.
                </p>
              </div>

           
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Freeboard;
