import * as React from 'react';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, MaskedTextBox, NumericTextBox, Checkbox, ColorPicker, Switch, RadioGroup, Slider, SliderLabel, RangeSlider, TextArea, Rating } from '@progress/kendo-react-inputs';
import { DatePicker, TimePicker, DateTimePicker, DateRangePicker, DateInput } from '@progress/kendo-react-dateinputs';
import { Label, Error, Hint, FloatingLabel } from '@progress/kendo-react-labels';
import { Upload } from '@progress/kendo-react-upload';
import { DropDownList, AutoComplete, MultiSelect, ComboBox, MultiColumnComboBox, DropDownTree } from '@progress/kendo-react-dropdowns';
import { processTreeData, expandedState } from './tree-data-operations';
import { useRef, useEffect, useState } from 'react';
import Slab from '../images/form/slabOrGrade.png';
import Basement from '../images/form/basement.png';
import PiersOrPiles from '../images/form/piersOrPiles.png';
import Crawlspace from '../images/form/crawlspace.png';
import Long from '../images/form/long.png';
import Square from '../images/form/square.png';
import Rectangle from '../images/form/rectangle.png';

export const FormInput = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>{label}</Label>
        <div className={'k-form-field-wrap'}>
          <Input valid={valid} type={type} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </div>
      </FieldWrapper>;
};



export const FormDropdownImageHomeShape = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    options,
    value,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  const getImageSize = (value) => {
    switch (value) {
      case '1': // Square option
        return { height: '100px', width: '100px' };
      case '2': // Rectangle option
        return { height: '100px', width: '200px' };
      default: // Long option or any other value
        return { height: '100px', width: '300px' };
    }
  };

  const getImageSrc = (value) => {
    if (value === '1') {
      return Square;
    } else if (value === '2') {
      return Rectangle;
    } else {
      return Long;
    }
  };

  const imageSize = getImageSize(value);
  const imageSrc = getImageSrc(value);

  return (
    <div>
      <div>
        <label htmlFor={id} id={labelId}>
          {label}
        </label>
        <select
          {...others}
          id={id}
          disabled={disabled}
          aria-describedby={`${hintId} ${errorId}`}
          aria-labelledby={labelId}
          value={value} // Set the selected value
          style={{
            width: '100%',
            height: '32px',
            fontSize: '16px',
            padding: '5px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            backgroundColor: '#ffffff',
            color: '#333',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            transition: 'box-shadow 0.3s ease'
          }}
        >
          {/* Add a default option */}
          <option value="">Select an option</option>
          {Array.isArray(options) ? (
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          ) : null}
        </select>
        {showHint && <span id={hintId}>{hint}</span>}
        {showValidationMessage && <span id={errorId}>{validationMessage}</span>}
      </div>

      {/* Display the image based on the selected value */}
      {value && (
        <div style={{ marginTop: '8px' }}>
          <img src={imageSrc} alt={value} style={{ height: imageSize.height, width: imageSize.width }} />
        </div>
      )}
    </div>
  );
};


export const FormDropdownImageFoundationType = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    options,
    value,
    ...others
  } = fieldRenderProps;

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  const getImageSrc = () => {
    if (value === '1') {
      return Basement;
    } else if (value === '2') {
      return Crawlspace;
    }
    else if (value === '4') {
      return Slab;
     } else {
      return PiersOrPiles;
    }
  };

  return (
    <div>
      <div>
        <label htmlFor={id} id={labelId}>
          {label}
        </label>
        <select
          {...others}
          id={id}
          disabled={disabled}
          aria-describedby={`${hintId} ${errorId}`}
          aria-labelledby={labelId}
          value={value} // Set the selected value
          style={{
            width: '100%',
            height: '32px',
            fontSize: '16px',
            padding: '5px',
            border: '1px solid #ddd',
            borderRadius: '5px',
            backgroundColor: '#ffffff',
            color: '#333',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
            transition: 'box-shadow 0.3s ease'
          }}
        >
          {/* Add a default option */}
          <option value="">Select an option</option>
          {Array.isArray(options) ? (
            options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          ) : null}
        </select>
        {showHint && <span id={hintId}>{hint}</span>}
        {showValidationMessage && <span id={errorId}>{validationMessage}</span>}
      </div>

      {/* Display the image based on the selected value */}
      {value && (
        <div style={{ marginTop: '8px' }}>
          <img src={getImageSrc()} alt={value} style={{ height: '200px', width: '200px', }} />
        </div>
      )}
    </div>
  );
};


export const FormRadioGroup = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>{label}</Label>
        <RadioGroup ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} valid={valid} disabled={disabled} ref={editorRef} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};


export const FormButton = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    buttonOptions,
    value, // This should be an array of objects with format {id: 'button1', label: 'Button 1'}
    ...others
  } = fieldRenderProps;

  const [selectedButton, setSelectedButton] = React.useState(value || null);
  const editorRef = React.useRef(null);

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  

  const handleButtonClick = (buttonId) => {
    setSelectedButton(buttonId);
    // Create a synthetic event to pass to fieldRenderProps.onChange
    
    const syntheticEvent = {
      target: {
        name: fieldRenderProps.name, // Field name, required by Formik
        value: buttonId, // New field value
      },
    };
    fieldRenderProps.onChange(syntheticEvent);
  }

  const buttonStyle = {
    padding: '10px',
    marginLeft: '0px',
    marginRight: '22px',
    border: '1.8px solid black',
    backgroundColor: 'white',
    cursor: 'pointer',
    fontsize: '15px',
    width: '160px',
    fontweight: 'bold',
    transition: 'all 0.3s ease',
  boxshadow: '0 4px 6px rgba(0, 0, 0, 0.1)',  
  // color: '#007BFF', /* Change text color to blue */
  // border: '1.8px solid #007BFF', /* Add blue border */  
  borderRadius: '6px',
  boxshadow: '0 4px 6px rgba(0, 0, 0, 0.1)',

  
  };

  const selectedButtonStyle = {
    ...buttonStyle,
    backgroundColor: 'lightblue',
  };

  return <FieldWrapper>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>{label}</Label>
        <div aria-describedby={`${hintId} ${errorId}`} aria-labelledby={labelId} ref={editorRef} {...others} >
          {buttonOptions.map(button => (
            <button 
              key={button.id} 
              type="button" // This prevents the form from being submitted when the button is clicked
              disabled={disabled} 
              onClick={() => handleButtonClick(button.id)}
              style={button.id === selectedButton ? selectedButtonStyle : buttonStyle}
            >
              {button.label}
            </button>
          ))}
        </div>
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};




export const FormNumericTextBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Label editorId={id} editorValid={valid} editorDisabled={disabled}>{label}</Label>
        <NumericTextBox ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormCheckbox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    valid,
    disabled,
    hint,
    optional,
    label,
    visited,
    modified,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Checkbox ariaDescribedBy={`${hintId} ${errorId}`} label={label} labelOptional={optional} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormSwitch = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    optional,
    id,
    valid,
    disabled,
    hint,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled} optional={optional}>
          {label}
        </Label>
        <Switch ref={editorRef} ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormMaskedTextBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
        <div className={'k-form-field-wrap'}>
          <MaskedTextBox ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} {...others} />
          {showHint && <Hint id={hintId}>{hint}</Hint>}
          {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </div>
      </FieldWrapper>;
};
export const FormTextArea = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
        <TextArea valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormColorPicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <ColorPicker ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};




export const FormSlider = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    data,
    min,
    max,
    ...others
  } = fieldRenderProps;

  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  const handleChange = event => {
    const value = parseInt(event.target.value);
    const validValues = data.map(Number);
    const closestValue = validValues.reduce((a, b) => {
      return Math.abs(b - value) < Math.abs(a - value) ? b : a;
    });
    editorRef.current.value = closestValue;
    others.input.onChange(closestValue);
  };

  const stepValues = data.join(',');

  const sliderLabelsStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '10px',
  };

  const sliderLabelStyle = {
   
    textAlign: 'center',
    width: '30px',
  };

   const slidercontainer= {
    width: '400px', /* Set the desired width of the slider container */
  }
  

  return (
    <div className="slidercontainer">
      <label
        id={labelId}
        ref={editorRef}
        htmlFor={id}
        style={{
          display: 'flex',
          marginBottom: '5px',
          fontWeight: valid ? 'bold' : 'normal',
          color: valid ? 'black' : 'red',
          pointerEvents: disabled ? 'none' : 'auto',
        }}
      >
        {label}
      </label>
      <input
        type="range"
        aria-labelledby={labelId}
        aria-describedby={`${hintId} ${errorId}`}
        ref={editorRef}
        id={id}
        disabled={disabled}
        min={min}
        max={max}
        step={stepValues}
        onChange={handleChange}
        style={{
          pointerEvents: disabled ? 'none' : 'auto',
          height: '10px',
          padding: '0',
          width: '100%', /* Set the width to 100% to fill the container */
        }}
        {...others}
      />
      <div style={sliderLabelsStyle}>
        {data.map(value => (
          <span key={value} style={sliderLabelStyle}>
            {value}
          </span>
        ))}
      </div>
      {showHint && <div id={hintId}>{hint}</div>}
      {showValidationMessage && <div id={errorId}>{validationMessage}</div>}
    </div>
  );
};




export const FormRating = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    hint,
    disabled,
    optional,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <Label editorId={id} editorValid={valid} optional={optional}>{label}</Label>
        <Rating valid={valid} id={id} disabled={disabled} ariaDescribedBy={`${hintId} ${errorId}`} {...others} />
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormUpload = fieldRenderProps => {
  const {
    value,
    id,
    optional,
    label,
    hint,
    validationMessage,
    touched,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  const onChangeHandler = event => {
    fieldRenderProps.onChange({
      value: event.newState
    });
  };
  const onRemoveHandler = event => {
    fieldRenderProps.onChange({
      value: event.newState
    });
  };
  return <FieldWrapper>
        <Label id={labelId} editorId={id} optional={optional}>
          {label}
        </Label>
        <Upload id={id} autoUpload={false} showActionButtons={false} multiple={false} files={value} onAdd={onChangeHandler} onRemove={onRemoveHandler} ariaDescribedBy={`${hintId} ${errorId}`} ariaLabelledBy={labelId} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};


export const FormDropdown = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    id,
    label,
    valid,
    disabled,
    hint,
    visited,
    modified,
    options,
    ...others
  } = fieldRenderProps;

  const editorRef = React.useRef(null);

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';

  return (
    <FieldWrapper>
      <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
        {label}
      </Label>
      <select
        {...others}
        id={id}
        ref={editorRef}
        disabled={disabled}
        aria-describedby={`${hintId} ${errorId}`}
        aria-labelledby={labelId}
        style={{
          width: '100%',
          height: '32px',
          fontSize: '16px',
          padding: '5px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          backgroundColor: '#ffffff',
          color: '#333',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)',
          transition: 'box-shadow 0.3s ease'
        }}
      >
        <option value="">Select</option> {/* Added this line */}
        {Array.isArray(options) ? (
          options.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))
        ) : null}
      </select>
      {showHint && <Hint id={hintId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </FieldWrapper>
  );
};


// export const FormDropdownwithoutselectdefault = fieldRenderProps => {
//   const {
//     validationMessage,
//     touched,
//     id,
//     label,
//     valid,
//     disabled,
//     hint,
//     visited,
//     modified,
//     options,
//     ...others
//   } = fieldRenderProps;

//   const editorRef = React.useRef(null);

//   const showValidationMessage = touched && validationMessage;
//   const showHint = !showValidationMessage && hint;
//   const hintId = showHint ? `${id}_hint` : '';
//   const errorId = showValidationMessage ? `${id}_error` : '';
//   const labelId = label ? `${id}_label` : '';

//   return (
//     <FieldWrapper>
//       <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
//         {label}
//       </Label>
//       <select
//         {...others}
//         id={id}
//         ref={editorRef}
//         disabled={disabled}
//         aria-describedby={`${hintId} ${errorId}`}
//         aria-labelledby={labelId}
//       >
//         {Array.isArray(options) ? (
//           options.map(option => (
//             <option key={option.value} value={option.value}>
//               {option.label}
//             </option>
//           ))
//         ) : null}
//       </select>
//       {showHint && <Hint id={hintId}>{hint}</Hint>}
//       {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
//     </FieldWrapper>
//   );
// };




export const FormAutoComplete = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <AutoComplete ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormComboBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <ComboBox ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormMultiColumnComboBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  const columns = [{
    field: 'id',
    header: <span>header</span>,
    width: '100px'
  }, {
    field: 'name',
    header: 'Name',
    width: '300px'
  }, {
    field: 'position',
    header: 'Position',
    width: '300px'
  }];
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <MultiColumnComboBox ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} columns={columns} textField={'name'} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormMultiSelect = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <MultiSelect ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormDropDownTree = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    data,
    ...others
  } = fieldRenderProps;
  const {
    value,
    selectField,
    expandField,
    dataItemKey,
    filter
  } = others;
  const [expanded, setExpanded] = React.useState([data[0][dataItemKey]]);
  const treeData = React.useMemo(() => processTreeData(data, {
    expanded,
    value,
    filter
  }, {
    selectField,
    expandField,
    dataItemKey,
    subItemsField: 'items'
  }), [data, expanded, value, filter, selectField, expandField, dataItemKey]);
  const onExpandChange = React.useCallback(event => setExpanded(expandedState(event.item, dataItemKey, expanded)), [expanded, dataItemKey]);
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const labelId = label ? `${id}_label` : "";
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <DropDownTree ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} data={treeData} onExpandChange={onExpandChange} dataItemKey={others.dataItemKey} textField={others.textField} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormDatePicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    hintDirection,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <div className={'k-form-field-wrap'}>
          <DatePicker ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
          {showHint && <Hint id={hintId} direction={hintDirection}>{hint}</Hint>}
          {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
        </div>
      </FieldWrapper>;
};
export const FormDateTimePicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <DateTimePicker ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormTimePicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <TimePicker ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormDateInput = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <DateInput ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormDateRangePicker = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    wrapperStyle,
    ...others
  } = fieldRenderProps;
  const editorRef = React.useRef(null);
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  const labelId = label ? `${id}_label` : '';
  return <FieldWrapper style={wrapperStyle}>
        <Label id={labelId} editorRef={editorRef} editorId={id} editorValid={valid} editorDisabled={disabled}>
          {label}
        </Label>
        <DateRangePicker ariaLabelledBy={labelId} ariaDescribedBy={`${hintId} ${errorId}`} ref={editorRef} valid={valid} id={id} disabled={disabled} {...others} />
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};
export const FormFloatingNumericTextBox = fieldRenderProps => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    optional,
    value,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hintId = showHint ? `${id}_hint` : '';
  const errorId = showValidationMessage ? `${id}_error` : '';
  return <FieldWrapper>
        <FloatingLabel optional={optional} editorValue={value} editorId={id} editorValid={valid} editorDisabled={disabled} label={label}>
          <NumericTextBox ariaDescribedBy={`${hintId} ${errorId}`} value={value} valid={valid} id={id} disabled={disabled} {...others} />
        </FloatingLabel>
        {showHint && <Hint id={hintId}>{hint}</Hint>}
        {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
      </FieldWrapper>;
};