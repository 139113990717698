// Tes.js
import React, { useEffect } from "react";

function Tes() {
  useEffect(() => {
    // Redirect to external URL when component mounts
    window.location.href = process.env.REACT_APP_DOMAIN + "api.html";
  }, []);

  // You can render a loading indicator or any other content while redirecting
  return (
    <>
    
    </>
  );
}

export default Tes;
