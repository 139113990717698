
import React, { useEffect, useState, useContext, createContext } from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormDropdown, FormButton, FormDropdownImage, FormDropdownImageFoundationType,FormDropdownImageHomeShape } from './form-components';
import axios from 'axios';
import firstFloorHeightImg from '../images/form/firstFloorHeight.png';

export const Home = () => {
  const [userTypes, setUserTypes] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [typeUseID, setTypeUseID] = useState([]);
  const [address, setaddress] = useState([]);
  const [homeCondition, setHomeCondition] = useState([]);
  const [numOfStories, setNumOfStories] = useState([]);
  const [mortgage, setMortgage] = useState([]);
  const [homeShape, setHomeShape] = useState([]);
  const [foundationtype, setFoundationType] = useState([]);
  const [livableArea, setLivableArea] = useState('');
  const [floodVents, setFloodVents] = useState([]);
  const [mandE, setMandE] = useState([]);
  const [selectedHomeCondition, setSelectedHomeCondition] = useState(null);
  const [selectedTypeUseID, setSelectedTypeUseID] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [barrierIslandIndicator, setBarrierIslandIndicator] = useState([]);
  const [floor1to3, setfloor1to3] = useState([]); // for selectedTypeUseID === 1, 2, 4
  const [floor1to4, setfloor1to4] = useState([]); // for selectedTypeUseID === 5,6
  const [floor1to100, setfloor1to100] = useState([]);// for selectedTypeUseID === 3,7
  const [firstFloorHeight, setfirstFloorHeight] = useState([]);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
    const baseURL = '${process.env.REACT_APP_API_DOMAIN}'; // Replace with your base URL
  
    const fetchData = async () => {
      try {
        const userTypeResponse = await axios.get(`${baseURL}/rr2-userType/`);
        setUserTypes(userTypeResponse.data.results);
  
        const addressResponse = await axios.get(`${baseURL}/address/`);
        setaddress(addressResponse.data.results);
  
        const typeUseIDResponse = await axios.get(`${baseURL}/rr2-typeUse/`);
        setTypeUseID(typeUseIDResponse.data.results);
  
        const homeConditionResponse = await axios.get(`${baseURL}/rr2-homeCondition/`);
        setHomeCondition(homeConditionResponse.data.results);
  
        const numOfStoriesResponse = await axios.get(`${baseURL}/rr2-numOfStories/`);
        setNumOfStories(numOfStoriesResponse.data.results);
  
        const foundationtypeResponse = await axios.get(`${baseURL}/rr2-foundationType/`);
        setFoundationType(foundationtypeResponse.data.results);
  
        const mortgageResponse = await axios.get(`${baseURL}/rr2-mortgage/`);
        setMortgage(mortgageResponse.data.results);
  
        const floodVentsResponse = await axios.get(`${baseURL}/rr2-floodVents/`);
        setFloodVents(floodVentsResponse.data.results);
  
        const mandEResponse = await axios.get(`${baseURL}/rr2-MandE/`);
        setMandE(mandEResponse.data.results);
  
        const barrierIslandIndicatorResponse = await axios.get(`${baseURL}/rr2-bi/`);
        setBarrierIslandIndicator(barrierIslandIndicatorResponse.data.results);
  
        const floor1to3Response = await axios.get(`${baseURL}/rr2-floor1to3/`);
        setfloor1to3(floor1to3Response.data.results);
  
        const floor1to100Response = await axios.get(`${baseURL}/rr2-floor1to100/`);
        setfloor1to100(floor1to100Response.data.results);
  
        const floor1to4Response = await axios.get(`${baseURL}/rr2-floor1to4/`);
        setfloor1to4(floor1to4Response.data.results);

        const homeShapeResponse = await axios.get(`${baseURL}/rr2-homeShape/`);
        setHomeShape(homeShapeResponse.data.results);

        setLoading(false); // Data fetched, set loading to false
  
      } catch (error) {
        console.log(error);
        setLoading(false); // Handle error case by still setting loading to false
      }
    };
  
    fetchData();
  }, []);
  
  const handleUserTypeChange = (event) => {
    const userType = event.target.value;
    setSelectedUserType(userType);
    setSelectedHomeCondition(null);
    setLivableArea('');
    const selectedUserTypeID = parseInt(event.target.value);
    localStorage.setItem('selectedUserType', selectedUserTypeID);
    const selectedLabel = userTypes.find((item) => item.id === selectedUserType)?.Name;
    console.log(selectedLabel);
  };
  const handleHomeConditionChange = (event) => {
    setSelectedHomeCondition(event.target.value);
  };
  const handleTypeUseIDChange = (event) => {
    const typeUseID = parseInt(event.target.value);
    setSelectedTypeUseID(typeUseID);
  };
  
  
const renderFloorDetails = () => {
  if (selectedTypeUseID === 1 || selectedTypeUseID === 2 || selectedTypeUseID === 4) {
    return (
      <div className="form-row">
        <label htmlFor="floor1to3ID" className="form-label">
          Number of Stories (1 to 3)
        </label>
        <div className="form-field">
          <Field
            key={'floor1to3ID'}
            id={'floor1to3ID'}
            name={'floor1to3ID'}
            layout={'horizontal'}
            component={FormDropdown}
            options={floor1to3.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>
    );
  } else if (selectedTypeUseID === 3 || selectedTypeUseID === 7) {
    return (
      <div className="form-row">
        <label htmlFor="floor1to100ID" className="form-label">
          Number of Stories (1 to 100)
        </label>
        <div className="form-field">
          <Field
            key={'floor1to100ID'}
            id={'floor1to100ID'}
            name={'floor1to100ID'}
            layout={'horizontal'}
            component={FormDropdown}
            options={floor1to100.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>
    );
  } else if (selectedTypeUseID === 5 || selectedTypeUseID === 6) {
    return (
      <div className="form-row">
        <label htmlFor="floor1to4ID" className="form-label">
          Number of Stories (1 to 4)
        </label>
        <div className="form-field">
          <Field
            key={'floor1to4ID'}
            id={'floor1to4ID'}
            name={'floor1to4ID'}
            layout={'horizontal'}
            component={FormDropdown}
            options={floor1to4.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>
    );
  }
  return null;
};


  const renderFieldsBasedOnUserType = () => {
    if (selectedUserType === 1 || selectedUserType === 2) {
      return (
        <>
      <div className="form-row">
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <div className="form-field">
          <Field
            key={'address'}
            id={'address'}
            name={'address'}
            type={'address'}
            component={FormInput}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="barrierIslandIndicator" className="form-label">
          Barrier Island Indicator
        </label>
        <div className="form-field">
          <Field
            key={'barrierIslandIndicator'}
            id={'barrierIslandIndicator'}
            name={'barrierIslandIndicator'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={barrierIslandIndicator.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="typeOfUseID" className="form-label">
          Type of use
        </label>
        <div className="form-field">
          <Field
            key={'typeOfUseID'}
            id={'typeOfUseID'}
            name={'typeOfUseID'}
            layout={'horizontal'}
            component={FormDropdown}
            options={typeUseID.map((item) => ({ value: item.id, label: item.Name }))}
            onChange={handleTypeUseIDChange}
          />
        </div>
      </div>

{/* Other way to add Tool tip */}

{/* <div>
      <label htmlFor="home condition">
        Home Condition
        <img
          src="./images/blue_question.png"
          alt="info-icon"
          title="An existing home is a home that currently exists, and a future home is a home that is being planned to build in the future"
          width="20"
          height="20"
        />
      </label>
Give the field here

<Field
            key={'homeConditionID'}
            id={'homeConditionID'}
            name={'homeConditionID' }
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={homeCondition.map((item) => ({ id: item.id, label: item.Name }))}
            onChange={handleHomeConditionChange}
          />

</div> */}



<div className="form-row">
        <label htmlFor="homeConditionID" className="form-label">
          Home condition
          <img
            src="./images/blue_question.png"
            alt="info-icon"
            title="An existing home is a home that currently exists, and a future home is a home that is being planned to build in the future"
            width="13"
            height="13"
            style={{ marginLeft: '5px' }} 
          />
        </label>
        <div className="form-field">
          <Field
            key={'homeConditionID'}
            id={'homeConditionID'}
            name={'homeConditionID'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={homeCondition.map((item) => ({ id: item.id, label: item.Name }))}
            onChange={handleHomeConditionChange}
          />
        </div>
      </div>
          {selectedHomeCondition === 1 && (
            <>
               <div className="form-row">
        <label htmlFor="livableArea" className="form-label">
          Livable Area
          <img
            src="./images/blue_question.png"
            alt="info-icon"
            title="The measurement of the livable area only includes areas taht you commonly use or have access to on a daily basis. If unknown, one can get this from real estate  websites"
            width="13"
            height="13"
            style={{ marginLeft: '5px' }} 
          />
        </label>
        <div className="form-field">
          <Field
            key={'livableArea'}
            id={'livableArea'}
            name={'livableArea'}
            type={'livableArea'}
            component={FormInput}
            data={livableArea}
            onChange={(event) => setLivableArea(event.target.value)}
          />
        </div>
      </div>

               {/* Render the numOfStories component */}
            {renderFloorDetails()}
            
            <div className="form-row">
        <label htmlFor="firstFloorHeight" className="form-label">
          First Floor Height (feet)
          <span>
      <img src="./images/firstFloorHeight.png"/>
    </span>
          <img
            src="./images/blue_question.png"
            width="13"
            height="13"
            style={{ marginLeft :'5px' }}
     
          />
        </label>
        <div className="form-field">
          <Field
            key={'firstFloorHeight'}
            id={'firstFloorHeight'}
            name={'firstFloorHeight'}
            type={'firstFloorHeight'}
            component={FormInput}
            data={firstFloorHeight.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>
              
      <div className="form-row" style={{display: 'flex'}}>
        <label htmlFor="homeShape" className="form-label">
          Home Shape
        </label>
        <div className="form-field">
          <Field
            key={'homeShapeID'}
            id={'homeShapeID'}
            name={'homeShapeID'}
            component={FormDropdownImageHomeShape}
            options={homeShape.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>


      <div className="form-row">
        <label htmlFor="foundationTypeID" className="form-label">
          Foundation type
        </label>
        <div className="form-field">
          <Field
            key={'foundationTypeID'}
            id={'foundationTypeID'}
            name={'foundationTypeID'}
            layout={'horizontal'}
            component={FormDropdownImageFoundationType}
            options={foundationtype.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="floodVentsID" className="form-label">
          Flood Vent
        </label>
        <div className="form-field">
          <Field
            key={'floodVentsID'}
            id={'floodVentsID'}
            name={'floodVentsID'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={floodVents.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="MandEID" className="form-label">
          Machinery and Equipment (Above First Floor Height)
        </label>
        <div className="form-field">
          <Field
            key={'MandEID'}
            id={'MandEID'}
            name={'MandEID'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={mandE.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>
            </>
          )}
          {selectedHomeCondition === 2 && (
            <>
            
            <div className="form-row">
  <label htmlFor="livableArea" className="form-label">
    Livable Area
    <img
      src="./images/blue_question.png"
      alt="info-icon"
      title="The measurement of the livable area only includes areas that you commonly use or have access to on a daily basis. If unknown, one can get this from real estate websites"
      width="13"
      height="13"
      style={{ marginLeft: '5px' }} 
    />
  </label>
  <div className="form-field">
    <Field
      key={'livableArea'}
      id={'livableArea'}
      name={'livableArea'}
      type={'livableArea'}
      component={FormInput}
      data={livableArea}
      onChange={(event) => setLivableArea(event.target.value)}
    />
  </div>
</div>

 {/* Render the numOfStories component */}
 {renderFloorDetails()}

 <div className="form-row">
        <label htmlFor="firstFloorHeight" className="form-label">
          First Floor Height (feet)
          <span>
      <img src="./images/firstFloorHeight.png"/>
    </span>
          <img
            src="./images/blue_question.png"
            width="13"
            height="13"
            style={{ marginLeft :'5px' }}
     
          />
        </label>
        <div className="form-field">
          <Field
            key={'firstFloorHeight'}
            id={'firstFloorHeight'}
            name={'firstFloorHeight'}
            type={'firstFloorHeight'}
            component={FormInput}
            data={firstFloorHeight.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

<div className="form-row">
  <label htmlFor="foundationTypeID" className="form-label">
    Foundation type
  </label>
  <div className="form-field">
    <Field
      key={'foundationTypeID'}
      id={'foundationTypeID'}
      name={'foundationTypeID'}
      layout={'horizontal'}
      component={FormDropdownImageFoundationType}
      options={foundationtype.map((item) => ({ value: item.id, label: item.Name }))}
    />
  </div>
</div>

<div className="form-row">
  <label htmlFor="floodVentsID" className="form-label">
    Flood Vent
  </label>
  <div className="form-field">
    <Field
      key={'floodVentsID'}
      id={'floodVentsID'}
      name={'floodVentsID'}
      layout={'horizontal'}
      component={FormButton}
      buttonOptions={floodVents.map((item) => ({ id: item.id, label: item.Name }))}
    />
  </div>
</div>

<div className="form-row">
  <label htmlFor="MandEID" className="form-label">
    Machinery and Equipment (Above First Floor Height)
  </label>
  <div className="form-field">
    <Field
      key={'MandEID'}
      id={'MandEID'}
      name={'MandEID'}
      layout={'horizontal'}
      component={FormButton}
      buttonOptions={mandE.map((item) => ({ id: item.id, label: item.Name }))}
    />
  </div>
</div>

            </>
          )}
        </>
      );
    } else if (selectedUserType === 3) {
      return (
        <>
       <div className="form-row">
        <label htmlFor="address" className="form-label">
          Address
        </label>
        <div className="form-field">
          <Field
            key={'address'}
            id={'address'}
            name={'address'}
            type={'address'}
            component={FormInput}
            data={userTypes.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="barrierIslandIndicator" className="form-label">
          Barrier Island Indicator
        </label>
        <div className="form-field">
          <Field
            key={'barrierIslandIndicator'}
            id={'barrierIslandIndicator'}
            name={'barrierIslandIndicator'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={barrierIslandIndicator.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="typeOfUseID" className="form-label">
          Type of use
        </label>
        <div className="form-field">
          <Field
            key={'typeOfUseID'}
            id={'typeOfUseID'}
            name={'typeOfUseID'}
            layout={'horizontal'}
            component={FormDropdown}
            options={typeUseID.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="livableArea" className="form-label">
          Livable Area 
          <img
            src="./images/blue_question.png"
            alt="info-icon"
            title="The measurement of the livable area only includes areas that you commonly use or have access to on a daily basis. If unknown, one can get this from real estate websites"
            width="13"
            height="13"
            style={{ marginLeft: '5px' }} 
          />
        </label>
        <div className="form-field">
          <Field
            key={'livableArea'}
            id={'livableArea'}
            name={'livableArea'}
            type={'livableArea'}
            component={FormInput}
            data={livableArea}
            onChange={(event) => setLivableArea(event.target.value)}
          />
        </div>
      </div>

      {renderFloorDetails()}

      <div className="form-row">
        <label htmlFor="firstFloorHeight" className="form-label">
          First Floor Height (feet)
          <span>
      <img src="./images/firstFloorHeight.png"/>
    </span>
          <img
            src="./images/blue_question.png"
            width="13"
            height="13"
            style={{ marginLeft :'5px' }}
     
          />
        </label>
        <div className="form-field">
          <Field
            key={'firstFloorHeight'}
            id={'firstFloorHeight'}
            name={'firstFloorHeight'}
            type={'firstFloorHeight'}
            component={FormInput}
            data={firstFloorHeight.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="foundationTypeID" className="form-label">
          Foundation type
        </label>
        <div className="form-field">
          <Field
            key={'foundationTypeID'}
            id={'foundationTypeID'}
            name={'foundationTypeID'}
            layout={'horizontal'}
            component={FormDropdownImageFoundationType}
            options={foundationtype.map((item) => ({ value: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="floodVentsID" className="form-label">
          Flood Vent
        </label>
        <div className="form-field">
          <Field
            key={'floodVentsID'}
            id={'floodVentsID'}
            name={'floodVentsID'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={floodVents.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>

      <div className="form-row">
        <label htmlFor="MandEID" className="form-label">
          Machinery and Equipment (Above First Floor Height)
        </label>
        <div className="form-field">
          <Field
            key={'MandEID'}
            id={'MandEID'}
            name={'MandEID'}
            layout={'horizontal'}
            component={FormButton}
            buttonOptions={mandE.map((item) => ({ id: item.id, label: item.Name }))}
          />
        </div>
      </div>
          {/* Add more fields specific to renter */}
        </>
      );
    } else if (selectedUserType === 4) {
      return (
        <>
<div className="form-row">
  <label htmlFor="address" className="form-label">
    Address
  </label>
  <div className="form-field">
    <Field
      key={'address'}
      id={'address'}
      name={'address'}
      type={'address'}
      component={FormInput}
      data={userTypes.map((item) => ({ value: item.id, label: item.Name }))}
    />
  </div>
</div>

<div className="form-row">
  <label htmlFor="barrierIslandIndicator" className="form-label">
    Barrier Island Indicator
  </label>
  <div className="form-field">
    <Field
      key={'barrierIslandIndicator'}
      id={'barrierIslandIndicator'}
      name={'barrierIslandIndicator'}
      layout={'horizontal'}
      component={FormButton}
      buttonOptions={barrierIslandIndicator.map((item) => ({ id: item.id, label: item.Name }))}
    />
  </div>
</div>

<div className="form-row">
  <label htmlFor="floodrisk" className="form-label">
    Your estimated annual flood risk is
  </label>
  <div className="form-field">
    <Field
      key={'floodrisk'}
      id={'floodrisk'}
      name={'floodrisk'}
      type={'floodrisk'}
      component={FormInput}
    />
  </div>
</div>

          {/* Add more fields specific to community official */}
        </>
      );
    }
    // Add more conditions for other user types and their corresponding fields
    return null; // Return null if no user type is selected or no fields match the condition
  };
  return (
    <>
      <style>{`
         label {
          display: block;
          font-weight: bold;
          margin-bottom: 5px;
          // margin-left: 40px;
        }
        .form-container {
          width: 100%; /* Adjust this value to make the form wider */
          // margin: 0 auto;
          // margin-left: -340px; /* Add this line to add left margin */
          background-color: #f0f8ff;
          padding: 20px;
          border-radius: 15px;
          padding-right: 40px; /* To add the padding between the options and container */
        }
        .form-row {
          display: flex;
          align-items: center; /* Adjusted to align items at the top */
          margin-bottom: 20px;
        }
        .form-row label {
          flex: 0 0 340px; /* Added a fixed width for labels to create consistent spacing */
          font-weight: bold;
          margin-right: 10px;
          // margin-bottom: 20px;
        }
        .form-row .form-field {
          flex: 1;
          display: flex;
          flex-direction: column; /* Added to ensure error messages are displayed below the fields */
          gap: 10px;
        }
        .form-field-error {
          color: red;
        }

        .form-label {
          position: relative;
        }
        
        /* The span element for the tooltip image */
        .form-label span {
          display: none;
          position: absolute;
          top: 100%;
          left: 0;
          width: 200px; /* Adjust the width of the tooltip image */
          z-index:1;
        }
        .form-label span img {
          width: 800%; /* Set the width of the image to fit the tooltip container */
          /* Optionally, you can set max-width and max-height to limit the image size */
          max-width: 600px; 
           max-height: 550px; 
           marginLeft:30px;
           z-index:1;
        }
        
        /* Show the tooltip image when hovering over the img element */
        .form-label:hover span {
          display: block;
        }
      
      `}</style>
      <div className="form-container">
        
        {loading ? (
          <div>Loading...</div>
        ) : (

       <>

        <div className="form-row">
          <label>User Type</label>
          <div className="form-field">
          <Field
          key={'userTypeID'}
          id={'userTypeID'}
          name={'userTypeID'}
          // label={'User Type'}
          layout={'horizontal'}
          component={FormButton}
          buttonOptions={userTypes.map((item) => ({ id: item.id, label: item.Name }))}
          onChange={handleUserTypeChange}
        />
        </div>
        </div>
        
        {selectedUserType && renderFieldsBasedOnUserType()}
        </>
        )}
        
      </div>
    </>
  );
};
