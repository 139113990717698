import Hero from "../components/Hero";
import Freeboard from "../components/Freeboard";
import Footer from "../components/Footer";
import Progress from "../components/Progress";

function Home() {
  return (
    <>
      <Progress/>
      <Hero />
      <Freeboard />
      <Footer />
    </>
  );
}

export default Home;
