import React, { useState } from 'react';

function Footer() {
  const [email, setEmail] = useState('');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_DOMAIN}/rr2-subscription/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setSubscriptionSuccess(true);
        setEmail(''); // Clear the email input after successful subscription
      } else {
        alert('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Subscription failed. Please try again.');
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <footer>
      <hr className="hero-hr"></hr>
      <div className="container">
        <div className="footer-content">
          <ul className="footer-content__2">
            <li>
              <span>Flood Safe Home</span> 
            </li>
            <li>                
              <a href="https://www.lsuagcenter.com/topics/family_home/home/lahouse" target="_blank">
                <i className="fa-solid"></i> &nbsp; LaHouse
              </a>
            </li>
            <li>
              <a href="tel:2255787913">
                <i className="fa-solid fa-phone"></i> &nbsp; (225) 578-7913
              </a>
            </li>
            <li>
              <a href="mailto: LaHouse@agcenter.lsu.edu">
                <i className="fa-solid fa-envelope"></i>
                &nbsp; LaHouse@agcenter.lsu.edu
              </a>
            </li>
          </ul>

          <ul className="footer-content__2">
            <li>Working Hours</li>
            <li>Mon - Fri: 8:00AM - 4:30PM</li>
            <li>Sat - Sun: Closed</li>
          </ul>

          <ul className="footer-content__2">
            <li>Partnerships </li>
            <li>
              <a href="https://www.lsuagcenter.com/" target="_blank">LSU AgCenter</a>
            </li>
            <li>
              <a href="https://www.uno.edu/" target="_blank">University of New Orleans</a>
            </li>
            <li>
              <a href="https://www.laseagrant.org/" target="_blank">Louisiana Sea Grant</a>
            </li>
          </ul>
          <div>
          <ul className="footer-content__2">
            <li>Subscription</li>
            <li>
              <p>Subscribe your Email address for latest news & updates.</p>
            </li>
            <li>
              <form onSubmit={handleSubmit}>
                <input
                  type="email"
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={handleEmailChange}
                />
                <button type="submit" className="submit-email">
                  Subscribe
                </button>
              </form>
            </li>
          </ul>

          {/* Display success message below subscribe box */}
          {subscriptionSuccess && (
            <div style={{ fontSize: '19px', marginRight: '30px' , marginTop: '5px' }}>
              Subscription successful!
            </div>
          )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
