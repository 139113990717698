
import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput, FormSliderLabel } from './form-components';
import { FormRadioGroup, FormButton, FormRangeSlider,FormSlider } from './form-components';
import { federalAssistance, investmentType } from './data';
import { Slider, SliderLabel,  } from "@progress/kendo-react-inputs";
import { Input } from "@progress/kendo-react-inputs";
import { useEffect, useState } from 'react';
import axios from 'axios';

export const Financial = () => {
  const [federalAssistance, setFederalAssistance] = useState([]);
  const [investmentType, setInvestmentType] = useState([]);
  const [selectedFederalAssistance, setSelectedFederalAssistance] = useState('');
  const [selectedInvestmentType, setSelectedInvestmentType] = useState('');
  const [fieldValue, setFieldValue] = useState('20'); 
   const [mortgage, setMortgage] = useState([]);
   const [loading, setLoading] = useState(true);
   const [selectedUserType, setSelectedUserType] = useState(null);
   const [selectedUserTypeName, setSelectedUserTypeName] = useState(null);

  useEffect(() => {
    const baseURL = '${process.env.REACT_APP_API_DOMAIN}'; // Replace with your base URL
  
    const fetchData = async () => {
      try {
        const federalAssistanceResponse = await axios.get(`${baseURL}/rr2-federalAssistance/`);
        setFederalAssistance(federalAssistanceResponse.data.results);

          
        const mortgageResponse = await axios.get(`${baseURL}/rr2-mortgage/`);
        setMortgage(mortgageResponse.data.results);
  
        const investmentTypeResponse = await axios.get(`${baseURL}/rr2-investmentType/`);
        setInvestmentType(investmentTypeResponse.data.results);

        const userTypeFromLocalStorage = localStorage.getItem('selectedUserType');
        setSelectedUserType(userTypeFromLocalStorage ? parseInt(userTypeFromLocalStorage, 10) : null);

        if (userTypeFromLocalStorage) {
          fetchUserTypeName(parseInt(userTypeFromLocalStorage, 10)); // Call the function to fetch the user type name
        }
        
        setLoading(false); // Data fetched, set loading to false
      } catch (error) {
        console.log(error);
        setLoading(false); // Handle error case by still setting loading to false
      }
    };
  
    fetchData();
  }, []);
  
  
  const fetchUserTypeName = (userTypeFromLocalStorage) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}/rr2-userType/${userTypeFromLocalStorage}`)
      .then(response => {
          setSelectedUserTypeName(response.data.Name); // Assuming the API response has a 'name' property
      })
      .catch(error => {
        console.error(error);
      });
  };
  
  const handleInvestmentTypeChange = (event) => {
    setSelectedInvestmentType(event.target.value);
  };
 
  return (
    <div>
      <style>{`
        label {
          display: block;
          font-weight: bold;
          margin-bottom: 5px;
        }
        .form-container {
          width: 100%;
          margin: 0 auto;
          background-color: #f0f8ff;
          padding: 20px;
          border-radius: 15px;
          padding-right: 40px;
        }
        .form-row {
          display: flex;
          align-items: center;
          margin-bottom: 18px;
        }
        .form-row label {
          flex: 0 0 340px;
          font-weight: bold;
          margin-right: 10px;
        }
        .form-row .form-field {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .form-field-error {
          color: red;
        }
      `}</style>

          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px'}}>
            <div style={{ textAlign: 'center' }}>
              <p>User Type: {selectedUserTypeName}</p>
              <p>Address: {}</p>
            </div>
      </div>
  
      <div className="form-container">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="form-row">
              <label htmlFor="mortgage" className="form-label">
                Mortgage
              </label>
              <div className="form-field">
                <Field
                  key={'mortgageID'}
                  id={'mortgageID'}
                  name={'mortgageID'}
                  layout={'horizontal'}
                  component={FormButton}
                  buttonOptions={mortgage.map((item) => ({ id: item.id, label: item.Name }))}
                />
              </div>
            </div>
            <div className="form-row">
              <label>Federal Assistance</label>
              <div className="form-field">
                <Field
                  key={'federalAssistanceID'}
                  id={'federalAssistanceID'}
                  name={'federalAssistanceID'}
                  layout={'horizontal'}
                  component={FormButton}
                  buttonOptions={federalAssistance.map(item => ({ id: item.id, label: item.Name }))}
                />
              </div>
            </div>
            <div className="form-row">
              <label>Investment Type</label>
              <div className="form-field">
                <Field
                  key={'investmentTypeID'}
                  id={'investmentTypeID'}
                  name={'investmentTypeID'}
                  layout={'horizontal'}
                  component={FormButton}
                  buttonOptions={investmentType.map(item => ({ id: item.id, label: item.Name }))}
                  onChange={handleInvestmentTypeChange}
                />
              </div>
            </div>
            {selectedInvestmentType === 2 && (
              <>
                {/* Remaining fields go here */}
                <div className="form-row">
                  <label>Home equity loan interest rate (%)</label>
                  <div className="form-field">
                    <Field
                      key={'homeEquityLoanInterestRate'}
                      id={'homeEquityLoanInterestRate'}
                      name={'homeEquityLoanInterestRate'}
                      type={'homeEquityLoanInterestRate'}
                      defaultValue={'7.5'}
                      component={FormInput}
                    />
                  </div>
                </div>

                <div className="form-row">
              <label >Home equity loan period</label>
              <div className="form-field">
                <Field
                  key={'homeEquityLoanPeriod'}
                  id={'homeEquityLoanPeriod'}
                  name={'homeEquityLoanPeriod'}
                  data={[0, 10, 20, 30, 40]} // Example data for slider labels
                  type={'range'}
                  step={10}
                  min={0} // Minimum value for the slider
                  max={40} // Maximum value for the slider
                  component={FormSlider}
                />
                <div>{/* Add a placeholder for slider label, if needed */}</div>
              </div>
            </div>

                <div className="form-row">
              <label >Mortgage interest rate (%)</label>
              <div className="form-field" >
                <Field
                  key={'mortgageInterestRate'}
                  id={'mortgageInterestRate'}
                  name={'mortgageInterestRate'}
                  type={'homeEquityLoanInterestRate'}
                  defaultValue={'7'}
                  component={FormInput}
                />
              </div>
            </div>

            <div className="form-row">
              <label >Mortgage period</label>
              <div className="form-field">
                <Field
                  key={'mortgagePeriod'}
                  id={'mortgagePeriod'}
                  name={'mortgagePeriod'}
                  step={10}
                  data={[0, 10, 20, 30, 40]} // Example data for slider labels
                  type={'range'}
                  min={0} // Minimum value for the slider
                  max={40} // Maximum value for the slider
                  component={FormSlider}
                />
                <div>{/* Add a placeholder for slider label, if needed */}</div>
              </div>
            </div>
                {/* ... more form rows ... */}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
            }