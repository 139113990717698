
import * as React from 'react';
import { Field } from '@progress/kendo-react-form';
import { FormInput } from './form-components';
import { FormAutoComplete, FormRadioGroup, FormTextArea, FormDropdown, FormButton } from './form-components';
import { floodInsurance, priorClaims } from './data';
import { useEffect, useState } from 'react';
import axios from 'axios';
export const Insurance = () => {
  const [floodInsurance, setFloodInsurance] = useState([]);
  const [priorClaims, setPriorClaims] = useState([]);
  const [showFields, setShowFields] = useState(false);
  const [crsRating, setcrsRating] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState(null);
  const [buildingReplacementValueError, setBuildingReplacementValueError] = useState([]);
  const [buildingCoverageValueError, setbuildingCoverageValueError]= useState([]);
  const [buildingDeductibleValueError, setbuildingDeductibleValueError]= useState([]);
  const [contentsReplacementValueError, setContentsReplacementValueError]= useState([]);
  const [contentsCoverageValueError, setcontentsCoverageValueError]= useState([]);
  const [contentsDeductibleValueError, setcontentsDeductibleValueError]= useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [selectedUserTypeName, setSelectedUserTypeName] = useState(null);
  useEffect(() => {
    const baseURL = '${process.env.REACT_APP_API_DOMAIN}'; 
    
    const fetchData = async () => {
      try {
        const floodInsuranceResponse = await axios.get(`${baseURL}/rr2-floodInsurance/`);
        setFloodInsurance(floodInsuranceResponse.data.results);
  
        const priorClaimsResponse = await axios.get(`${baseURL}/rr2-priorClaims/`);
        setPriorClaims(priorClaimsResponse.data.results);
  
        const crsRatingResponse = await axios.get(`${baseURL}/rr2-CRSRating/`);
        setcrsRating(crsRatingResponse.data.results);
  
        const userTypeFromLocalStorage = localStorage.getItem('selectedUserType');
        setSelectedUserType(userTypeFromLocalStorage ? parseInt(userTypeFromLocalStorage, 10) : null);

        if (userTypeFromLocalStorage) {
          fetchUserTypeName(parseInt(userTypeFromLocalStorage, 10)); // Call the function to fetch the user type name
        }
        
        setLoading(false); // Set loading to false after fetching data
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchData();
  }, []);



  const fetchUserTypeName = (userTypeFromLocalStorage) => {
    axios
      .get(`${process.env.REACT_APP_API_DOMAIN}+ /rr2-userType/${userTypeFromLocalStorage}`)
      .then(response => {
          setSelectedUserTypeName(response.data.Name); // Assuming the API response has a 'name' property
      })
      .catch(error => {
        console.error(error);
      });
  };
  


  
  const handleFloodInsuranceChange = (event) => {
    const selectedFloodInsuranceID = parseInt(event.target.value);
    setShowFields(selectedFloodInsuranceID === 1);
  };

  const buildingReplacementValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setBuildingReplacementValueError('You have to enter a valid number.');
    } else if (parsedValue < 10000 || parsedValue > 1000000) {
      setBuildingReplacementValueError('Value must be between $10000 and $100000');
    } else {
      setBuildingReplacementValueError(''); // No validation error
    }
  };

  const buildingCoverageValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setbuildingCoverageValueError('You have to enter a valid number.');
    } else if (parsedValue < 60000 || parsedValue > 250000) {
      setbuildingCoverageValueError('Value must be between $60000 and $250000');
    } else {
      setbuildingCoverageValueError(''); // No validation error
    }
  };

  const buildingDeductibleValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setbuildingDeductibleValueError('You have to enter a valid number.');
    } else if (parsedValue < 1000 || parsedValue > 10000) {
      setbuildingDeductibleValueError('Value must be between $1000 and $10000');
    } else {
      setbuildingDeductibleValueError(''); // No validation error
    }
  };

  const contentsReplacementValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setContentsReplacementValueError('You have to enter a valid number.');
    } else if (parsedValue < 5000 || parsedValue > 1000000) {
      setContentsReplacementValueError('Value must be between $5000 and $1000000');
    } else {
      setContentsReplacementValueError(''); // No validation error
    }
  };

  const contentsCoverageValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setcontentsCoverageValueError('You have to enter a valid number.');
    } else if (parsedValue < 60000 || parsedValue > 250000) {
      setcontentsCoverageValueError('Value must be between $60000 and $250000');
    } else {
      setcontentsCoverageValueError(''); // No validation error
    }
  };

  const contentsDeductibleValueValidator = value => {
    const parsedValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(parsedValue)) {
      setcontentsDeductibleValueError('You have to enter a valid number.');
    } else if (parsedValue < 1000 || parsedValue > 10000) {
      setcontentsDeductibleValueError('Value must be between $1000 and $10000');
    } else {
      setcontentsDeductibleValueError(''); // No validation error
    }
  };

  return (
    
    <>
      <style>{`
        label {
          display: block;
          font-weight: bold;
          margin-bottom: 5px;
          // margin-left: 40px;
        }
        .form-container {
          width: '100%'; /* Adjust this value to make the form wider */
          margin: 0 auto;
          // margin-left: -340px; /* Add this line to add left margin */
          background-color: #f0f8ff;
          padding: 20px;
          border-radius: 15px;
         padding-right: 40px; /* To add the padding between the options and container */
        }
        .form-row {
          display: flex;
          // align-items: center; /* Adjusted to align items at the top */
          margin-bottom: 10px;
        }
        .form-row label {
          flex: 0 0 340px; /* Added a fixed width for labels to create consistent spacing */
          font-weight: bold;
          margin-right: 10px;
        }
        .form-row .form-field {
          flex: 1;
          display: flex;
          flex-direction: column; /* Added to ensure error messages are displayed below the fields */
          gap: 10px;
        }
        .form-field-error {
          color: red;
        }
      `}</style>

      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px'}}>
            <div style={{ textAlign: 'center' }}>
              <p>User Type: {selectedUserTypeName}</p>
              <p>Address: {}</p>
            </div>
      </div>
        
      <div className="form-container">

      {loading ? (
          <div>Loading...</div>
        ) : (
          <>

         <div className="form-row"  style={{alignItems: 'center'}}>
          <label>Do you want to consider flood insurance?</label>
          <div className="form-field">
            <Field
              key={'floodInsuranceID'}
              id={'floodInsuranceID'}
              name={'floodInsuranceID'}
              layout={'horizontal'}
              component={FormButton}
              buttonOptions={floodInsurance.map(item => ({ id: item.id, label: item.Name }))}
              onChange={handleFloodInsuranceChange}
            />
          </div>
        </div>

        {showFields && (selectedUserType === 2 || selectedUserType === 1 || selectedUserType === 4) && (
          <>
            <div  className="form-row">
              <label>Building replacement value</label>
              <div className="form-field">
                <Field
                  key={'buildingReplacementValue'}
                  id={'buildingReplacementValue'}
                  name={'buildingReplacementValue'}
                  component={FormInput}
                  defaultValue="250000"
                  onChange={(event) => buildingReplacementValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage starts at $10,000.'}
                />
                {/* {buildingReplacementValueError && <div className="form-field-error">{buildingReplacementValueError}</div>} */}
              </div>
            </div>

            <div className="form-row">
              <label>Building Coverage</label>
              <div className="form-field">
                <Field
                  key={'buildingCoverage'}
                  id={'buildingCoverage'}
                  name={'buildingCoverage'}
                  component={FormInput}
                  defaultValue="250000"
                  onChange={(event) => buildingCoverageValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage is from $60,000 to $250,000.'}
                />
                {/* {buildingCoverageValueError && <div className="form-field-error">{buildingCoverageValueError}</div>} */}
              </div>
            </div>

            <div className="form-row">
              <label>Building Deductible</label>
              <div className="form-field">
                <Field
                  key={'buildingDeductible'}
                  id={'buildingDeductible'}
                  name={'buildingDeductible'}
                  component={FormInput}
                  defaultValue="1500"
                  onChange={(event) => buildingDeductibleValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage is from $1,000 to $10,000.'}
                />
                {/* {buildingDeductibleValueError && <div className="form-field-error">{buildingDeductibleValueError}</div>} */}
              </div>
            </div>
          </>
        )}

        {showFields && (selectedUserType === 3 || selectedUserType === 1 || selectedUserType === 4) && (
          <>
            <div  className="form-row">
              <label>Contents replacement value</label>
              <div className="form-field">
                <Field
                  key={'contentsReplacementValue'}
                  id={'contentsReplacementValue'}
                  name={'contentsReplacementValue'}
                  component={FormInput}
                  defaultValue="100000"
                  onChange={(event) => contentsReplacementValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage starts at $5,000.'}
                />
                {/* {contentsReplacementValueError && <div className="form-field-error">{contentsReplacementValueError}</div>} */}
              </div>
            </div>

            <div className="form-row">
              <label>Contents Coverage</label>
              <div className="form-field">
                <Field
                  key={'contentsCoverage'}
                  id={'contentsCoverage'}
                  name={'contentsCoverage'}
                  component={FormInput}
                  defaultValue="100000"
                  onChange={(event) => contentsCoverageValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage is from $25,000 to $100,000.'}
                />
                {/* {contentsCoverageValueError && <div className="form-field-error">{contentsCoverageValueError}</div>} */}
              </div>
            </div>

            <div className="form-row">
              <label>Contents Deductible</label>
              <div className="form-field">
                <Field
                  key={'contentsDeductible'}
                  id={'contentsDeductible'}
                  name={'contentsDeductible'}
                  component={FormInput}
                  defaultValue="1500"
                  onChange={(event) => contentsDeductibleValueValidator(event.target.value)}
                  hint={'The National Flood Insurance Program (NFIP) range for coverage is from $1,000 to $10,000.'}
                />
                {/* {contentsDeductibleValueError && <div className="form-field-error">{contentsDeductibleValueError}</div>} */}
              </div>
            </div>
          </>
        )}

        {showFields && (
          <>
            <div className="form-row">
              <label>Prior Claims</label>
              <div className="form-field">
                <Field
                  key={'priorClaimsID'}
                  id={'priorClaimsID'}
                  name={'priorClaimsID'}
                  layout={'horizontal'}
                  component={FormDropdown}
                  options={priorClaims.map(item => ({ value: item.id, label: item.Name }))}
                />
              </div>
            </div>

            <div className="form-row">
              <label>CRS Rating</label>
              <div className="form-field">
                <Field
                  key={'crsRating'}
                  id={'crsRating'}
                  name={'crsRating'}
                  layout={'horizontal'}
                  component={FormDropdown}
                  options={crsRating.map(item => ({ value: item.id, label: item.Name }))}
                />
              </div>
            </div>
          </>
        )}
        </>
      )}
      </div>
    </>
  );
};