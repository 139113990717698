import React from "react";
import "../src/dist/styles.css";
import About from "./Pages/About";
import Home from "./Pages/Home";
import Models from "./Pages/Models";
import Team from "./Pages/Team";
import Navbar from "../src/components/Navbar";
import { Route, Routes, useLocation } from "react-router-dom";
import Contact from "./Pages/Contact";
import NotFoundPage from "./Pages/NotFoundPage"; // Import your custom 404 page
import Calculation from "./Pages/Calculation"; 

function App() {
  const location = useLocation();

  // Define an array of paths where the navbar should be displayed
  const navbarPaths = ["/", "/about", "/contact" ,"/team" , "/calculation"]; // Add more paths as needed

  // Check if the current location matches any path where the navbar should be displayed
  const showNavbar = navbarPaths.includes(location.pathname);

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="models" element={<Models />} />
        <Route path="team" element={<Team />} />
        <Route path="contact" element={<Contact />} />
        <Route path="calculation" element={<Calculation/>}/>

        {/* Catch-all route for handling 404 errors */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default App;
