import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import Farris from "../images/team/farris.jpg";
import Jenkins from "../images/team/jenkins.jpg";
import Friedland from "../images/team/Friedland.jpg";
import Rohli from "../images/team/rohli.jpg";
import Lee from "../images/team/lee.jpg";
import Mostafiz from "../images/team/mostafiz.jpg";
import Mithila from "../images/team/mithila.jpg";
import Assi from "../images/team/assi.jpg"
import Tao from "../images/team/tao.jpg";
import Jiyoung from "../images/team/jiyoung.jpg";
import Rahim from "../images/team/rahim.jpg";
import { Link } from 'react-router-dom';
import Progress from "../components/Progress";



function Team() {  
    const teamProfessors = [
    { img: Farris, name: "Monica Teets Farris", job: "Director", aff:"UNO-CHART", email:"mateets@uno.edu"},
    { img: Friedland, name: "Carol J. Friedland", job: "Director", aff:"Louisiana House (LaHouse)", email:"CFriedland@agcenter.lsu.edu"},
    { img: Jenkins, name: "Pamela J. Jenkins", job: "Professor Emeritus", aff:"University of New Orleans", email:"pjenkins@uno.edu"},
    { img: Rohli, name: "Robert V. Rohli", job: "Professor", aff:"Louisiana State Univesrity", email: "rohli@lsu.edu"},
    { img: Lee, name: "Yongcheol Lee", job: "Associate Professor", aff:"Louisiana State Univesrity", email:"yclee@lsu.edu" },
  ];
  const teamassistantPrpfessors =[
    {img: Mostafiz, name: "Rubayet Bin Mostafiz", job: "Assistant Professor", aff:"Louisiana State Univesrity", email:"RMostafiz@agcenter.lsu.edu"},
    { img: Rahim, name: "Md Adilur Rahim", job: "Assistant Professor", aff:"Louisiana State Univesrity",  email: "MRahim@agcenter.lsu.edu"},
  ];
  const teamPostDocs = [  
    // { img: Mostafiz, name: "Rubayet Bin Mostafiz", job: "Postdoctoral Researcher", aff:"Louisiana House (LaHouse)",  email:"RMostafiz@agcenter.lsu.edu"},
    { img: Mithila, name: "Shifat P Mithila", job: "Postdoctoral Researcher", aff:"Louisiana House (LaHouse)",  email: "SMithila@agcenter.lsu.edu" },
  ];
  const teamGA = [
    { img: Assi, name: "Ayat Al Assi", job: "Research Assistant", aff:"Louisiana House (LaHouse)",  email: "AAlAssi@agcenter.lsu.edu" },
    // { img: Tao, name: "Hoang T Tao", job: "Research Assistant", aff:"UNO-CHART", email: "httao1@uno.edu" },
    { img: Jiyoung, name: "Jiyoung Lee", job: "Research Assistant", aff:"Louisiana House (LaHouse)",  email: "JiyoungLee@agcenter.lsu.edu"},
    // { img: Rahim, name: "Md Adilur Rahim", job: "Research Assistant", aff:"Louisiana House (LaHouse)",  email: "MRahim@agcenter.lsu.edu"},
  ];
  return (
    <>
    <Progress/>
      <section className="team-page">
        <HeroPages name="Our Team" />
        <div className="cotnainer">
        <div className="team-header">
          <h1>Project Investigation Professors</h1>
        </div>
          <div className="team-container">
            {teamProfessors.map((ppl, id) => (
              <div key={id} className="team-container__box">
                <div className="team-container__box__img-div">
                  <img src={ppl.img} alt="team_img" />
                </div>
                <div className="team-container__box__descr">
                  <h3>{ppl.name}</h3>
                  <p>{ppl.job}</p>
                  <p>{ppl.aff}</p>
                  <p>{ppl.email}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="team-header">
          <h1>Assistant Professors</h1>
        </div>
          <div className="team-container">
            {teamassistantPrpfessors.map((ppl, id) => (
              <div key={id} className="team-container__box">
                <div className="team-container__box__img-div">
                  <img src={ppl.img} alt="team_img" />
                </div>
                <div className="team-container__box__descr">
                  <h3>{ppl.name}</h3>
                  <p>{ppl.job}</p>
                  <p>{ppl.aff}</p>
                  <p>{ppl.email}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="team-header">
            <h1>Postdoctoral Researcher</h1>
          </div>
          <div className="team-container">
            {teamPostDocs.map((ppl, id) => (
              <div key={id} className="team-container__box">
                <div className="team-container__box__img-div">
                  <img src={ppl.img} alt="team_img" />
                </div>
                <div className="team-container__box__descr">
                  <h3>{ppl.name}</h3>
                  <p>{ppl.aff}</p>
                  <p>{ppl.email}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="team-header">
            <h1>Research Assistant</h1>
          </div>
          <div className="team-container">
            {teamGA.map((ppl, id) => (
              <div key={id} className="team-container__box">
                <div className="team-container__box__img-div">
                  <img src={ppl.img} alt="team_img" />
                </div>
                <div className="team-container__box__descr">
                  <h3>{ppl.name}</h3>
                  <p>{ppl.aff}</p>
                  <p>{ppl.email}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>     
      <Footer />
    </>
  );
}

export default Team;
