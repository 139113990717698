import React from 'react';

function Progress() {
    return (
        <div>
            <style>
                {`

                .text {
                    background-color: red;
                    text-align: center;
                    padding: 10px;
                    color: white;
                    font-size: 23px;
                }
                
                `}
            </style>
            <div  className="text">
                Web Tool Under Development
            </div>
        </div>

    );
}

export default Progress;



