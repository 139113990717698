import { Link } from "react-router-dom";
import BgShape from "../images/hero/hero-bg.png";
import { useEffect, useState } from "react";

function Hero() {
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: (0, 0), behavior: "smooth" });
  };

  const calculateBtn = () => {
    document
      .querySelector("#calculator-section")
      .scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.pageYOffset > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);
  return (
    <>
      <section id="home" className="hero-section">
        <div className="container">
          <img className="bg-shape" src={BgShape} alt="bg-shape" />
          <div className="hero-content">
            <div className="hero-content__text">
              <h1>What is Flood Safe Home 2.0?</h1>
              <p>
              Flood Safe Home provides personalized recommendations of the first floor height from the ground. The potential savings from additional elevation of the first floor height. Use our web tool to find how much money you can save from flood loss reduction and Risk Rating 2.0 flood insurance premium.
              </p>
              <div className="hero-content__text__btns">
                <Link
                  onClick={calculateBtn}
                  className="hero-content__text__btns__calculator"
                  to="/api.html"
                >
                  Use the calculator &nbsp;<i class="fa fa-calculator"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* page up */}
        <div
          onClick={scrollToTop}
          className={`scroll-up ${goUp ? "show-scroll" : ""}`}
        >
          <i className="fa-solid fa-angle-up"></i>
        </div>
      </section>
    </>
  );
}

export default Hero;
