import Footer from "../components/Footer";
import HeroPages from "../components/HeroPages";
import StudyArea from "../images/about/StudyArea.jpg";
import Freeboard from "../images/about/feeboard.png";
import Progress from "../components/Progress";

function About() {
  return (
    <>
      <Progress/>
      <section className="about-page">
        <HeroPages name="About" />
        <div className="container">
          <div className="about-main">
            <div className="about-main__text">
              <h2>Our Project</h2>
              <h3>RATIONALE</h3>
              <p>
              Louisiana is very flood prone with NUMBER incidents in the last NUMBER years. Adding freeboard is one-way homeowners can protect their homes and other buildings from flooding. 
              </p>

              <br></br>
              <br></br>
              <h3>WHAT IS FREEBOARD?</h3>
              <p>            
              Freeboard is an additional elevation added above the base flood elevation. Without freeboard, half of all houses in the special flood hazard area (SFHA; i.e., the area within the 1 percent APE flood zone) are expected to flood at least once, with 12 percent expected to flood twice and nearly 3 percent expected to flood three times.              
              <img className="StudyArea" src={Freeboard} alt="Freeboard" />           
              </p>
            
              
              <br></br>
              <br></br>
              <h3>THE IMPACTS OF FLOODING</h3>
              <p>
              Flooded homes result in death, injury, loss of belongings, lost future opportunities, and short- and long-term post-flood relocation. In addition to the economic costs associated with relocation, floods and post-flood recovery can be distressing as they displace families and communities. (Simms et al. 2021) (World Meteorological Organization 2020). Flooding also typically impacts the most marginalized communities and can exacerbate existing inequalities (López-Marrero and Tschakert 2011). Repeated flooding, like that experienced in coastal Louisiana, is a impediment to resilient communities and economies.
              </p>


              <br></br>
              <br></br>
              <h3>WHAT ARE THE COSTS?</h3>
              <p>            
              Adding additional elevation like freeboard does make the initial cost of a home more expensive; however, 2020-2022 Sea Grant research shows that a homeowner will save more money long term through reduced flood insurance premiums and reduced likelihood of flood damage.  
              </p>


              <br></br>
              <br></br>
              <h3>SO WHAT’S HOLIDNG US BACK? LET’S ALL GET FREEBOARDED HOMES!</h3>
              <p>            
              As living in a freeboarded home is a solution, the challenge arise as most homeowners are living in homes that are not already freeboarded. Raising a home is more expensive after a home is built than in the onset. Further, there are many social, economic and political factors that go into the Louisiana State Uniform Construction Code allowing for higher standards for communities that already have or want to enforce higher freeboard standards, thus the current enforcement is only of construction codes.
              </p>


              <br></br>
              <br></br>
              <h3>WHAT CAN BE DONE?</h3>
              <p>            
              lack of information at every level of the process including homeowners, elected officials, realtors, appraisers, assessors, contractors, developers, and other local stakeholders. This information needs to become multi-faceted. Further, with the availability of adequate data of the cost and benefits to flood mitigation strategies, residents would have the opportunity to make more resilient decisions (Taghinezhad et al. 2020, 2021). Government involvement and regulation on different levels will assist in needed policy creation. A combination of addressing the social, economic, and political barriers and addressing the pathways synergistically will enhance the sustainability of communities and economic resilience.
               </p> 


              <br></br>
              <br></br>
              <h3>GOALS OF THIS PROJECT</h3>
              <div> 
              <img className="StudyArea" src={StudyArea} alt="Study-Area" />           
              -	Enhance community resilience for pre- existing homes in Louisiana’s 20 coastal parishes. <br></br>
              -	Integrate engineering, physical and social sciences through cross- institutional expertise, UNO and LSU, and local, state and federal knowledge resources of the Project Advisory Board (PAB).
              </div>


              <br></br>
              <br></br>
              <h3>HYPOTHESIS OF RESEARCH</h3>
              <div class="about-div">         
                <b>H1)</b> Developing a common understanding of economic and social issues related to freeboard leads to enhanced community and stakeholder acceptance throughout the 20 parishes; <br></br>
                <b>H2)</b> Elevating existing homes to optimal freeboard levels results in lower life-cycle cost; and <br></br>
                <b>H3)</b> Greater acceptance of the economic and social benefits of freeboard may lead to new policy supporting freeboard throughout the 20 parishes. <br></br>
              </div>

              
              <br></br>
              <br></br>
              <h3>THE TEAM PROPOSES TO</h3>
              <div class="about-div">
                As steps toward the goal, the team proposes to:  <br></br>
                <div class="about-object"> 
                <b>O1)</b> build on its current research by discovering similarities and differences between barriers and incentives of freeboard for new construction vs. existing structures in Louisiana’s 20 coastal parishes through interviews and focus groups with stakeholders, and cross-parish data comparison;  <br></br>
                </div>    
                <div class="about-object">
                <b>O2)</b> collect base data and develop new economic risk models to estimate hazard AALs vis-à-vis existing structure SI/SD code requirements, elevation cost, and flood insurance premiums;  <br></br>
                </div>    
                <div class="about-object">
                <b>O3)</b> conduct GIS-based flood loss and loss reduction analyses by expanding capabilities of the team’s “Flood Safe Home” web-based decision support tool (DST, <a href="https://floodsafehome.lsu.edu/" target="_blank">https://floodsafehome.lsu.edu/</a>) to reflect the economic realities of existing homes across 20 coastal parishes; and O4) document and delineate the social, economic, and policy pathways to the use of freeboard, and enhance understanding of the extent to which this information may change stakeholder and community perspectives regarding implementing freeboard for existing residences. <br></br>
                </div>
              </div>
            </div>
          </div>
        </div>

   
      </section>  
      <Footer />
    </>
  );
}

export default About;
