import { useState } from "react";

function Faq() {
  const [activeQ, setActiveQ] = useState("q1");

  const openQ = (id) => {
    setActiveQ(activeQ === id ? "" : id);
  };

  const getClassAnswer = (id) => {
    return activeQ === id ? "active-answer" : "";
  };

  const getClassQuestion = (id) => {
    return activeQ === id ? "active-question" : "";
  };

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="faq-content">
            <div className="faq-content__title">
                <h2>Recommended articles</h2>
              <p>
                Frequently Asked Questions on Our Website: Answers to Common Concerns and Inquiries.
              </p>
            </div>

            <div className="all-questions">
              <div className="faq-box">
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__question  ${getClassQuestion("q1")}`}
                >
                  <p>1. Impacts and Outreach </p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q1"
                  onClick={() => openQ("q1")}
                  className={`faq-box__answer ${getClassAnswer("q1")}`}
                >
                The project team will use a multi-tiered approach to outreach. <br></br>
                1.	Project Highlights, Updates, and Communications <br></br>
                2.	Communities Implementing Sustainable Practices <br></br>
                3.	Communities That Were Involved in Hazard Resiliency Training <br></br>
                4.	Developed Tools, Technologies, and Products <br></br>
                5.	Public or Professional Presentations <br></br>
                6.	Professional Meetings and Workshops Organized <br></br>
                7.	Publications <br></br>
                8.	Number of Postsecondary Students and Degrees Financially-Supported by Sea Grant in Higher Education Programs (Undergraduate, Graduate) <br></br>
                9.	Professional/Academic Advancement of Students <br></br>
                10.	Leveraged Funding <br></br>
                11.	Economic and Societal Benefits <br></br>
                12.	Impacts and Accomplishments <br></br>
                12. Partners <br></br>
                </div>
              </div>
              <div className="faq-box">
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__question ${getClassQuestion("q2")}`}
                >
                  <p>2. Related papers</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q2"
                  onClick={() => openQ("q2")}
                  className={`faq-box__answer ${getClassAnswer("q2")}`}
                >
                 - Friedland, C.J., Lee, Y.C., Mostafiz, R.B., Lee, J., Mithila, S., Rohli, R.V., Rahim, M.A., and Gnan, E. (2023). FloodSafeHome: Evaluating benefits and savings of freeboard for improved decision-making in flood risk mitigation. Accepted at Frontiers in Communication, 8, Art. No. 1060901.doi: <a href="https://www.frontiersin.org/articles/10.3389/fcomm.2023.1060901/full" target="_blank">10.3389/fcomm.2023.1060901</a> <br></br>

                 - Gnan, E., Friedland, C.J., Mostafiz R.B., Rahim, M.A., Gentimis, T., Taghinazhad, A., and Rohli, R.V. (2022). Economically optimizing elevation of new, single-family residences for flood mitigation via life-cycle benefit-cost analysis. Frontiers in Environmental Science, 10, Art. No. 889239. doi: <a href="https://www.frontiersin.org/articles/10.3389/fenvs.2022.889239/full" target="_blank">10.3389/fenvs.2022.889239.</a> <br></br>

                 - Gnan, E., Friedland, C.J., Rahim, M.A., Mostafiz R.B., Rohli, R.V., Orooji, F., Taghinazhad, A., and McElwee, J. (2022). Improved building-specific flood risk assessment and implications of depth-damage function selection. Frontiers in Water, 4, Art. No. 919726. doi: <a href="https://www.frontiersin.org/journals/water/articles/10.3389/frwa.2022.919726/full" target="_blank"> 10.3389/frwa.2022.919726 </a><br></br>

                 - Kodavatiganti, Y., Friedland, C.J., Rahim, M.A., Mostafiz R.B., Taghinezhad, A., and Heil, S. (2023). Material quantities and estimated construction costs for new elevated IRC 2015-compliant single-family home foundations. Accepted at Frontiers in Built Environment, 9, Art. No. 1111563. doi:<a href="https://www.frontiersin.org/articles/10.3389/fbuil.2023.1111563/full" target="_blank"> 10.3389/fbuil.2023.1111563 </a><br></br>

                 - Al Assi, A., Mostafiz, R.B., Friedland, C.J., Rohli, R.V., and Rahim, M.A. (2023). Homeowner flood risk and risk reduction from home elevation between the 100-and 500-year Floodplains. Frontiers in Earth Science, 11, Art. No. 1051546. doi:<a href="https://www.frontiersin.org/articles/10.3389/feart.2023.1051546/full" target="_blank"> 10.3389/feart.2023.1051546 </a><br></br>

                 - Al Assi, A., Mostafiz, R.B., Friedland, C.J., Rahim, M.A., and Rohli, R.V. (2023). Flood risk assessment for residences at the neighborhood scale by owner/occupant type and first-floor height. Frontiers in Big Data, 5, Art. No. 997447. doi:<a href="https://www.frontiersin.org/articles/10.3389/fdata.2022.997447/full" target="_blank"> 10.3389/fdata.2022.997447 </a>
                </div>
              </div>
              {/* <div className="faq-box">
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__question ${getClassQuestion("q3")}`}
                >
                  <p>3. Qui enim sequi et voluptas porro qui reprehenderit distinctio sed velit praesentium et nemo esse.</p>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
                <div
                  id="q3"
                  onClick={() => openQ("q3")}
                  className={`faq-box__answer ${getClassAnswer("q3")}`}
                >
                  Ut voluptatem facere eum rerum provident in recusandae saepe sed aspernatur porro et illum commodi At ducimus voluptatem? Et ullam dicta vel rerum nesciunt sit modi sint sit galisum vitae qui voluptatibus iste aut unde internos.
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;
